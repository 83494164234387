import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import ImageGrid from "../components/ImageGrid";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
// import Content from "../components/Content";
import MarkdownContent from "../components/MarkdownContent";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export const ProcessPageTemplate = ({
  title,
  // description,
  steps,
  technique,
  approach,
  video,
}) => {
  // const PageContent = Content;

  return (
    <article className="page page--process">
      <div className="container-md">
        <div className="row align-items-start justify-content-center">
          <div className="col-lg-8 col-md-12">
      <h1 className="title mb-3">{title}</h1>
      <section className="approach mb-5 pb-3 pt-md-3">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h2>{approach.heading}</h2>
            <MarkdownContent className="content" content={approach.description} />
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
            <PreviewCompatibleImage imageInfo={approach.image} />
          </div>
        </div>
      </section>
      <section className="technique mb-5">
        <div className="row">
        <div className="col-md-6 col-sm-12 mb-3 order-md-last">
            <h2>{technique.heading}</h2>
            <MarkdownContent className="technique-description" content={technique.description} />
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
            <PreviewCompatibleImage imageInfo={technique.image} />
          </div>
        </div>
      </section>
      </div></div></div>
      <section className="steps bg-light my-0 py-5">
        <div className="container-md">
          <div className="row align-items-start">
            <div className="col">
              <h2 className="mb-4">Steps</h2>
              <ImageGrid gridItems={steps} /> 
            </div>
          </div>
        </div>
      </section>
      <section className="callout-video my-0 py-5 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-4 mx-auto">
                <div className="ratio ratio-16x9">
                  <iframe
                    src={video.embed.url || "https://player.vimeo.com/video/15439151?h=80ae4eab64&title=0&byline=0&portrait=0"}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    referrerPolicy="origin-when-cross-origin"
                    title={video.embed.title || "Video Player"}
                  ></iframe>
                </div>
              </div>
          </div>
        </div>
      </section>
    </article>
  );
};

ProcessPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  steps: PropTypes.array,
  approach: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  technique: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  video: PropTypes.shape({
    embed: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

const ProcessPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const title = frontmatter.title || null;
  const description = frontmatter.technique.description || data.excerpt || null;

  return (
    <Layout>
      <Helmet>
        {title ? <title>{title}</title> : null}
        {description ? <meta name="description" content={description} /> : null}
        {title ? <meta property="og:title" content={title} /> : null}
      </Helmet>
      <ProcessPageTemplate
        title={title}
        description={frontmatter.description}
        steps={frontmatter.steps}
        approach={frontmatter.approach}
        technique={frontmatter.technique}
        video={frontmatter.video}
      />
    </Layout>
  );
};

ProcessPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProcessPage;

export const processPageQuery = graphql`
  query ProcessPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      excerpt
      frontmatter {
        title
        description
        technique {
          heading
          description
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
        approach {
          heading
          description
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
        steps {
          image {
            childImageSharp {
              gatsbyImageData(width: 350, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
          text
        }
        video {
          embed {
            title
            url
          }
        }
      }
    }
  }
`;
