import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const ImageGrid = ({gridItems}) => (
  <div className="d-flex gap-3 justify-content-around flex-wrap">
    {gridItems && gridItems.length > 0 && gridItems.map((item, index) => (
      <div key={index} className="text-center d-inline-block">
        <div className="mb-2 d-inline-block">
          { item.caption && item && item.image && (
            <figure>
                  <PreviewCompatibleImage imageInfo={item} objectFit="contain" imgStyle={{width: '100%', height: 'auto', maxHeight: '400px'}} style={{width: '100%', height: 'auto', maxHeight: '400px'}} />
              <figcaption className="caption">{item.caption}</figcaption>
            </figure>
          )}
          { !item.caption && item && item.image && (
            <PreviewCompatibleImage imageInfo={item} objectFit="contain" imgStyle={{width: '100%', height: 'auto', maxHeight: '400px'}} style={{width: '100%', height: 'auto', maxHeight: '400px'}} />
          )}
        </div>
        { item.text && (
          <p>{item.text}</p>
        )}
      </div>
    ))}
  </div>
);

ImageGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default ImageGrid;
